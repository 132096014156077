<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu class="left-menu-box" title="政策动态" :menuData="menuData"></vertical-menu>
        <div class="right-list-box">
          <article-list :data="dataList" @handleClick="handleDetailClick"></article-list>
          <div class="footer">
            <div class="left">共 {{total}} 条</div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              layout="prev, pager, next"
              :page-size="7"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
export default {
  name: 'notice',
  components: {
    articleList, verticalMenu
  },
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 7
      },
      queryInfo: {
        keywords: null,
        type: 1,
      },
      menuData: [
        {
          'name': '通知公告',
          'code': 'tex'
        }
      ],
      dataList: [
      ],
      total: null
    }
  },
  created() {
    // 获取列表
    this.getList();
  },
  mounted() {

  },
  methods: {
    handleDetailClick(item) {
      this.$router.push({ path: '/intellectualProperty/noticeDetail', query: { id: item.id } })
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.intellectualProperty.policyList(params).then((res) => {
        let data = res.data;
        // data.rows.forEach(item => {
        //   if (!item.time && item.createTime) {
        //     item.time = item.createTime
        //   }
        // });
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    }
  }
}
</script>
<style scoped lang="less">
.policy-article-wrapper {
  display: flex;
  flex-direction: row;
  .left {
  }
  .right {
  }
}
</style>
